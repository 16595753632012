import React from 'react';
import { compose } from 'redux';

import { CONSTRAINT_TYPES } from 'dpl/shared/validations/constraints';
import withFormCapabilities from 'dpl/form/decorators/withFormCapabilities';
import withCurrentUser from 'dpl/decorators/withCurrentUser';
import LoginForm from 'dpl/components/LoginForm';
import postLoginRedirect from 'dpl/util/postLoginRedirect';
import { USER_TYPES } from 'dpl/shared/constants/shared';
import { get } from 'dpl/shared/utils/object';
import { sendAnalyticsIdentify } from 'dpl/util/analytics';

function LoginFormContainer(props) {
  return <LoginForm {...props} />;
}

export default compose(
  withCurrentUser,
  withFormCapabilities(() => ({}), {
    serverErrorCodesToIgnore: ['OTP_REQUIRED'],
    mapPropsAndStateToValidationSchema: () => ({
      email: CONSTRAINT_TYPES.EMAIL,
      password: CONSTRAINT_TYPES.PASSWORD
    }),
    onSubmit: (props, formStateForSubmission, payload = {}) => {
      const { login, onAuth } = props;

      return login({
        user: {
          ...formStateForSubmission,
          ...payload
        }
      }).then(json => {
        sendAnalyticsIdentify({ userId: get(json, 'data.data.user_id') });

        if (onAuth) {
          return onAuth(json);
        }

        if (get(json, 'data.data.buyer')) {
          return postLoginRedirect(USER_TYPES.BUYER);
        }

        return postLoginRedirect(USER_TYPES.BREEDER);
      });
    }
  })
)(LoginFormContainer);
