import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { isEmpty } from 'dpl/shared/utils/object';
import { AUTH_PATHS } from 'dpl/shared/constants/urls';
import ErrorWrapper from 'dpl/form/components/ErrorWrapper';
import Button from 'dpl/common/design_system/Button';

const OTP_CHANNEL_OPTIONS = [
  { label: 'Send code via sms', value: 'sms' },
  { label: 'Send code via email', value: 'email' },
  { label: 'Call me instead', value: 'call' }
];

export default function LoginForm({
  buttonClassName,
  formState,
  formErrors,
  addToFormState,
  handleFormFieldChange,
  hideForgotPassword,
  onPasswordResetClick,
  isFormSubmitting,
  didFormSubmissionFail,
  handleFormSubmission
}) {
  const [otpRequiredMessage, setOtpRequiredMessage] = useState(null);
  const [shouldShowOtpOptions, setShouldShowOtpOptions] = useState(false);

  function handleForgotPasswordClick(e) {
    e.preventDefault();
    onPasswordResetClick();
  }

  async function handleSubmit(payload = {}) {
    const res = await handleFormSubmission(payload);
    if (res?.response?.status === 401) {
      const json = await res.response.json();

      if (json.error_code === 'OTP_REQUIRED') {
        setOtpRequiredMessage(json.error);
        addToFormState('otp_channel', json.otp_channel);
      }
    }
  }

  function handleResendOtp(channel = formState.otp_channel) {
    return handleSubmit({
      otp_attempt: null,
      otp_channel: channel
    });
  }

  return (
    /* eslint-disable jsx-a11y/tabindex-no-positive */
    <div className="LoginForm">
      <form
        onSubmit={e => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        {otpRequiredMessage ? (
          <div className="mb8">
            <p className="dark-gray mb4">{otpRequiredMessage}</p>
            <p className="mb6">
              Didn&apos;t receive a code?&nbsp;
              <button
                type="button"
                onClick={() => handleResendOtp()}
                className="underline"
              >
                Resend
              </button>
            </p>
            <input
              tabIndex="1"
              name="otp_attempt"
              className="w-100 br1 ba b--light-gray ph4 pv2"
              value={formState.otp_attempt || ''}
              onChange={handleFormFieldChange}
              placeholder="123456"
              autoComplete="one-time-code"
            />
          </div>
        ) : (
          <>
            <div className="row mb6">
              <div className="col">
                <label htmlFor="email">
                  <p className="dark-gray mb2">Email address</p>
                  <ErrorWrapper
                    errors={formErrors.email}
                    isShown={didFormSubmissionFail}
                  >
                    <input
                      tabIndex="1"
                      name="email"
                      className="w-100 br1 ba b--light-gray ph4 pv2"
                      value={formState.email || ''}
                      onChange={handleFormFieldChange}
                      placeholder="jane@highpeaksfarm.com"
                      autoComplete="username"
                    />
                  </ErrorWrapper>
                </label>
              </div>
            </div>

            <div className="mb8 relative">
              <label htmlFor="password">
                <p className="dark-gray mb2">Password</p>
                <ErrorWrapper
                  errors={formErrors.password}
                  isShown={didFormSubmissionFail}
                >
                  <input
                    tabIndex="2"
                    name="password"
                    type="password"
                    autoCapitalize="none"
                    className="w-100 br1 ba b--light-gray ph4 pv2"
                    value={formState.password || ''}
                    onChange={handleFormFieldChange}
                    placeholder="6+ characters"
                    autoComplete="current-password"
                  />
                </ErrorWrapper>
              </label>
            </div>
          </>
        )}
        <div className="mb12">
          <button
            tabIndex="3"
            data-test-id="login_submit"
            type="submit"
            disabled={isFormSubmitting || !isEmpty(formErrors)}
            className={classnames(buttonClassName, {
              'button--loading': isFormSubmitting
            })}
          >
            Log in
          </button>

          {otpRequiredMessage && (
            <Button
              size="default"
              variant="ghost"
              className="mt3 w-100"
              onClick={() => setShouldShowOtpOptions(true)}
            >
              Sign in another way
            </Button>
          )}

          {shouldShowOtpOptions && (
            <div className="flex flex-column items-center">
              {OTP_CHANNEL_OPTIONS.map(({ label, value }) => (
                <Button
                  key={value}
                  size="tiny"
                  variant="ghost"
                  className="db"
                  onClick={() => handleResendOtp(value)}
                >
                  {label}
                </Button>
              ))}
            </div>
          )}

          {!(hideForgotPassword || otpRequiredMessage) && (
            <div className="mt3 tc">
              <a
                tabIndex="4"
                className="tc f2 link--primary blue"
                data-test-id="forgot_password_button"
                onClick={
                  onPasswordResetClick ? handleForgotPasswordClick : null
                }
                href={AUTH_PATHS.FORGOT_PASSWORD}
              >
                Forgot password?
              </a>
            </div>
          )}
        </div>
      </form>
    </div>
    /* eslint-enable jsx-a11y/tabindex-no-positive */
  );
}

LoginForm.propTypes = {
  formState: PropTypes.shape({
    otp_attempt: PropTypes.string,
    otp_channel: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formErrors: PropTypes.object.isRequired,
  handleFormFieldChange: PropTypes.func.isRequired,
  hideForgotPassword: PropTypes.bool,
  buttonClassName: PropTypes.string,
  onPasswordResetClick: PropTypes.func,
  handleFormSubmission: PropTypes.func.isRequired,
  isFormSubmitting: PropTypes.bool.isRequired,
  didFormSubmissionFail: PropTypes.bool.isRequired,
  addToFormState: PropTypes.func.isRequired
};

LoginForm.defaultProps = {
  buttonClassName: 'w-100 button button--primary',
  hideForgotPassword: false,
  onPasswordResetClick: null
};
