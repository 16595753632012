// WIP
// update default size prop from 'tiny' to 'default'
// check design file and update colors

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { isEmpty } from 'validate.js';

import Icon from '../components/Icon';

const BASE_CLASSES = 'fw-medium br2 ba tc';
const DEFAULT_ICON_SIZE = '18px';

export const BUTTON_VARIANTS = {
  primary: classnames(BASE_CLASSES, 'bg-blue-500 b--blue-500 white'),
  secondary: classnames(BASE_CLASSES, 'bg-pale-blue b--pale-blue stone-700'),
  danger: classnames(BASE_CLASSES, 'bg-subtle-gray b--subtle-gray rubber'),
  tertiary: classnames(BASE_CLASSES, 'bg-subtle-gray b--subtle-gray stone-700'),
  outlineBlue: classnames(BASE_CLASSES, 'b--stone-700 stone-700'), // this variant is currently not part of the design system
  outline: classnames(BASE_CLASSES, 'bg-white b--default stone-700'),
  ghost: classnames(BASE_CLASSES, 'b--transparent stone-700'),
  stone: classnames(BASE_CLASSES, ' b--default stone-700')
};

export const BUTTON_SIZES = {
  default: 'ph12 pv3',
  small: 'ph8 pv2',
  tiny: 'ph4 pv2 lg:font-14',
  icon: 'ph2 pv2'
};

function getIconComponent(name, size) {
  return <Icon key={name} name={name} height={size} width={size} />;
}

const Button = forwardRef(
  (
    {
      children,
      className,
      disabled,
      iconName,
      iconPosition,
      iconSize,
      variant,
      size,
      type,
      ...buttonProps
    },
    ref
  ) => {
    const hasIcon = !isEmpty(iconName);
    const finalChildren = [children];

    if (iconName) {
      const iconComponent = getIconComponent(iconName, iconSize);

      if (iconPosition === 'left') {
        finalChildren.unshift(iconComponent);
      } else {
        finalChildren.push(iconComponent);
      }
    }

    return (
      <button
        ref={ref}
        className={classnames(
          'DSButton',
          BUTTON_VARIANTS[variant],
          BUTTON_SIZES[size],
          disabled && 'o-50',
          hasIcon && 'flex justify-center items-center gap-8',
          className
        )}
        disabled={disabled}
        type={type} // eslint-disable-line react/button-has-type
        {...buttonProps}
      >
        {finalChildren}
      </button>
    );
  }
);

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  iconName: PropTypes.string,
  iconPosition: PropTypes.oneOf(['center', 'right', 'left']),
  iconSize: PropTypes.string,
  variant: PropTypes.oneOf(Object.keys(BUTTON_VARIANTS)),
  size: PropTypes.oneOf(Object.keys(BUTTON_SIZES)),
  type: PropTypes.string
};

Button.defaultProps = {
  children: null,
  className: null,
  disabled: false,
  iconName: null,
  iconPosition: 'left',
  iconSize: DEFAULT_ICON_SIZE,
  variant: 'primary',
  size: 'tiny',
  type: 'button'
};

Button.displayName = 'DSButton';

export default Button;
