import { USER_TYPES } from 'dpl/shared/constants/shared';
import {
  BREEDER_DASHBOARD_PATH,
  BUYER_MESSAGES_PATH
} from 'dpl/shared/constants/urls';
import { getSafeRedirectPath } from 'dpl/util/queryString';

export default function postLoginRedirect(userType) {
  const redirectTo = getSafeRedirectPath();

  if (redirectTo) {
    window.location = redirectTo;
  } else {
    window.location =
      userType === USER_TYPES.BREEDER
        ? BREEDER_DASHBOARD_PATH
        : BUYER_MESSAGES_PATH;
  }
}
